<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-button variant="primary" @click="$refs.addIntegrationModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="integrations" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="$refs.editIntegrationModal.open(props.row)" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                            />
                        </b-button>
                        <b-button variant="danger" @click="removeIntegration(props.row.id)">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                        </b-button>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>

        <AddIntegrationModal ref="addIntegrationModal" v-on:integrationAdded="loadData"/>

        <EditIntegrationModal ref="editIntegrationModal" v-on:integrationSaved="loadData"/>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddIntegrationModal from '@/views/Integration/AddIntegrationModal'
    import EditIntegrationModal from '@/views/Integration/EditIntegrationModal'

    export default {
        components: {
            EditIntegrationModal,
            AddIntegrationModal,
            BOverlay,
            BCard,
            BButton,
            BasicTable
        },
        data() {
            return {
                dataLoaded: false,
                integrations: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Type',
                        displayType: 0,
                        field: this.typeRenderer,
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Type'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {

                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/integration')
                loadPromise.then(function(response) {
                    thisIns.integrations = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })

            },
            removeIntegration(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/integration/${id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Integration removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            typeRenderer(rowObj) {
                switch (rowObj.type) {
                case 0:
                    return 'Fake ERP'
                case 1:
                    return 'Clocks PMS+'
                case 2:
                    return 'Reception Panels'
                case 3:
                    return 'Simple Reservation Service'
                default:
                    return 'Unknown'
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
