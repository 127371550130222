<template>
    <b-modal title="Add integration" v-model="addModalActive" size="lg" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="addObject.name"
                />
            </b-form-group>
            <b-form-group>
                <label for="categories">Type:</label>
                <v-select
                    id="categories"
                    v-model="addObject.type"
                    :reduce="type => type.id"
                    label="name"
                    :options="types"
                />
            </b-form-group>
            <template v-if="addObject.type === 0">

                <b-form-group>
                    <label>URL:</label>
                    <b-form-input
                        type="text"
                        placeholder="URL"
                        v-model="addObject.config.url"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Token:</label>
                    <b-form-input
                        type="text"
                        placeholder="Token"
                        v-model="addObject.config.token"
                    />
                </b-form-group>
            </template>
            <template v-if="addObject.type === 1">
                <b-form-group>
                    <label>API User:</label>
                    <b-form-input
                        type="text"
                        placeholder="User"
                        v-model="addObject.config.api_user"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API Key:</label>
                    <b-form-input
                        type="text"
                        placeholder="Key"
                        v-model="addObject.config.api_key"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API URL:</label>
                    <b-form-input
                        type="text"
                        placeholder="URL"
                        v-model="addObject.config.api_url"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API SubscriptionID:</label>
                    <b-form-input
                        type="text"
                        placeholder="SubscriptionID"
                        v-model="addObject.config.api_sub_id"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API AccountIDs:</label>  <b-button variant="success" @click="addAccountId()" >+</b-button>
                    <div v-for="(acc, index) in addObject.config.api_acc_ids"
                         v-bind:key="index">
                        <b-input-group class="mt-1">
                            <b-form-input
                                type="text"
                                placeholder="AccountID"
                                v-model="addObject.config.api_acc_ids[index]"
                            />
                            <b-input-group-append>
                                <b-button variant="danger" @click="removeAccountId(index)">-</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-form-group>
                <b-form-group>
                    <label>Normal check-in hour:</label>
                    <b-form-timepicker
                        placeholder="14:00"
                        v-model="addObject.config.check_in_hour"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Normal check-out hour:</label>
                    <b-form-timepicker
                        placeholder="11:00"
                        v-model="addObject.config.check_out_hour"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Use for orders:</label>
                    <b-form-checkbox
                        v-model="addObject.config.use_for_orders"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Use for bill:</label>
                    <b-form-checkbox
                        v-model="addObject.config.use_for_bill"
                    />
                </b-form-group>
            </template>
            <template v-if="addObject.type === 3">
                <b-form-group>
                    <label>URL:</label>
                    <b-form-input
                        type="text"
                        placeholder="URL"
                        v-model="addObject.config.url"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Token:</label>
                    <b-form-input
                        type="text"
                        placeholder="Token"
                        v-model="addObject.config.token"
                    />
                </b-form-group>
            </template>
        </template>
        <template #modal-footer>
            <b-button variant="danger" @click="addModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addIntegration">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BFormTimepicker, BInputGroup, BInputGroupAppend, BFormCheckbox} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BFormTimepicker,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            vSelect
        },
        data() {
            return {
                addObject: {
                    name: '',
                    type: 0,
                    config: {
                        url: '',
                        token: '',
                        api_user: '',
                        api_key: '',
                        api_url: '',
                        api_sub_id: '',
                        api_acc_ids: [''],
                        check_in_hour: '14:00',
                        check_out_hour: '11:00',
                        use_for_bill: true,
                        use_for_orders: true,
                        enabled: true
                    }
                },
                addModalActive: false,

                types: [
                    {
                        id: 0,
                        name: 'Fake ERP'
                    },
                    {
                        id: 1,
                        name: 'Clocks PMS+'
                    },
                    {
                        id: 2,
                        name:'Reception panel'
                    },
                    {
                        id:3,
                        name: 'Simple Reservation Service'
                    }
                ]
            }
        },
        methods: {
            open() {
                this.addModalActive = true
            },
            addIntegration() {
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/integration', this.addObject)
                loadPromise.then(function(response) {
                    thisIns.$printSuccess('Integration added')
                    thisIns.addModalActive = false
                    thisIns.addObject = {}
                    thisIns.$emit('integrationAdded', response.data.id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            addAccountId() {
                this.addObject.config.api_acc_ids.push('')
            },
            removeAccountId(index) {
                if (this.addObject.config.api_acc_ids.length > 1) {
                    this.addObject.config.api_acc_ids.splice(index, 1)
                } else {
                    this.$printError('Can\'t remove last account ID')
                }

            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
