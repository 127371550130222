<template>
    <b-modal title="Save integration" v-model="editModalActive" size="lg" no-close-on-backdrop>
        <template #default>
            <b-form-group>
                <label for="name">Name:</label>
                <b-form-input
                    id="name"
                    type="text"
                    placeholder="Name"
                    v-model="editObject.name"
                />
            </b-form-group>
            <template v-if="editObject.type === 0">
                <b-form-group>
                    <label for="categories">Type:</label>
                    <v-select
                        id="categories"
                        v-model="editObject.type"
                        :reduce="type => type.id"
                        label="name"
                        :options="types"
                    />
                </b-form-group>
                <b-form-group>
                    <label>URL:</label>
                    <b-form-input
                        type="text"
                        placeholder="URL"
                        v-model="editObject.config.url"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Token:</label>
                    <b-form-input
                        type="text"
                        placeholder="Token"
                        v-model="editObject.config.token"
                    />
                </b-form-group>
            </template>
            <template v-if="editObject.type === 1">
                <b-form-group>
                    <label>API User:</label>
                    <b-form-input
                        type="text"
                        placeholder="User"
                        v-model="editObject.config.api_user"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API Key:</label>
                    <b-form-input
                        type="text"
                        placeholder="Key"
                        v-model="editObject.config.api_key"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API URL:</label>
                    <b-form-input
                        type="text"
                        placeholder="URL"
                        v-model="editObject.config.api_url"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API SubscriptionID:</label>
                    <b-form-input
                        type="text"
                        placeholder="SubscriptionID"
                        v-model="editObject.config.api_sub_id"
                    />
                </b-form-group>
                <b-form-group>
                    <label>API AccountIDs:</label>  <b-button variant="success" @click="addAccountId()" >+</b-button>
                    <div v-for="(acc, index) in editObject.config.api_acc_ids"
                         v-bind:key="index">
                        <b-input-group class="mt-1">
                            <b-form-input
                                type="text"
                                placeholder="AccountID"
                                v-model="editObject.config.api_acc_ids[index]"
                            />
                            <b-input-group-append>
                                <b-button variant="danger" @click="removeAccountId(index)">-</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-form-group>
                <b-form-group>
                    <label>Normal check-in hour:</label>
                    <b-form-timepicker
                        placeholder="14:00"
                        v-model="editObject.config.check_in_hour"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Normal check-out hour:</label>
                    <b-form-timepicker
                        placeholder="11:00"
                        v-model="editObject.config.check_out_hour"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Use for orders:</label>
                    <b-form-checkbox
                        v-model="editObject.config.use_for_orders"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Use for bill:</label>
                    <b-form-checkbox
                        v-model="editObject.config.use_for_bill"
                    />
                </b-form-group>
            </template>
            <template v-if="editObject.type === 3">
                <b-form-group>
                    <label for="categories">Type:</label>
                    <v-select
                        id="categories"
                        v-model="editObject.type"
                        :reduce="type => type.id"
                        label="name"
                        :options="types"
                    />
                </b-form-group>
                <b-form-group>
                    <label>URL:</label>
                    <b-form-input
                        type="text"
                        placeholder="URL"
                        v-model="editObject.config.url"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Token:</label>
                    <b-form-input
                        type="text"
                        placeholder="Token"
                        v-model="editObject.config.token"
                    />
                </b-form-group>
            </template>
        </template>
        <template #modal-footer>
            <b-button variant="danger" @click="editModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="warning" @click="saveIntegration">
                <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal, BFormTimepicker, BInputGroup, BInputGroupAppend, BFormCheckbox} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            BFormTimepicker,
            BInputGroup,
            BInputGroupAppend,
            BFormCheckbox,
            vSelect
        },
        data() {
            return {
                editObject: {
                    name: '',
                    type: 0,
                    config: {
                        url: '',
                        token: '',
                        api_user : '',
                        api_key : '',
                        api_url : '',
                        api_sub_id : '',
                        api_acc_ids : [''],
                        check_in_hour: '',
                        check_out_hour: '',
                        use_for_bill: true,
                        use_for_orders: true
                    }

                },
                editModalActive: false,

                types: [
                    {
                        id: 0,
                        name: 'Fake ERP'
                    },
                    {
                        id: 1,
                        name: 'Clocks PMS+'
                    }
                ]
            }
        },
        methods: {
            open(integration) {
                this.editModalActive = true
                this.editObject = JSON.parse(JSON.stringify(integration))
            },
            saveIntegration() {
                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/integration/${  this.editObject.id}`, this.editObject)
                loadPromise.then(function(response) {
                    thisIns.$printSuccess('Integration saved')
                    thisIns.editModalActive = false
                    thisIns.editObject = {}
                    thisIns.$emit('integrationSaved', response.data.id)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            addAccountId() {
                this.editObject.config.api_acc_ids.push('')
            },
            removeAccountId(index) {
                if (this.editObject.config.api_acc_ids.length > 1) {
                    this.editObject.config.api_acc_ids.splice(index, 1)
                } else {
                    this.$printError('Can\'t remove last account ID')
                }

            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
